import axios from 'axios';
import { tokenName } from '../../../configs/app.json';

class AxiosService {
  constructor(preffix) {
    this.preffix = preffix;
    // Regular Expression to convert the base URL to API pointer
    // TODO, use the API url defined in .env file
    this.baseUrl = window.location.origin.replace(/^(https?:\/\/)([\w-]+)(\.[\w-]+\.[a-z]+)(.*)$/, '$1api$3$4') + `/${this.preffix}`;
    this.axios = axios;
    this.cancelTokenHandler = {};
  }

  setBaseUrl = (baseUrl = '') => {
    this.baseUrl = this.baseUrl + baseUrl;
  };

  getToken = () => {
    return localStorage.getItem(tokenName);
  };

  setToken = (token) => {
    return localStorage.setItem(tokenName, token);
  };

  getSource = () => {
    return axios.CancelToken.source();
  };

  getCancelToken = (key) => {
    this.cancelTokenHandler[key] = axios.CancelToken.source();
    return this.cancelTokenHandler[key].token;
  };

  previusRequestCancellation = (key) => {
    if (this.cancelTokenHandler.hasOwnProperty(key)) {
      return this.cancelTokenHandler[key].cancel('cancelled');
    }
    return null;
  };

  removeToken = () => {
    return localStorage.removeItem(tokenName);
  };

  request = async (config = {}) => {
    try {
      return await axios.request(config);
    } catch (error) {
      return error.request || error.response;
    }
  };

  get = async (url, config = {}) => {
    try {
      return await axios.get(this.baseUrl + url, config);
    } catch (error) {
      return error.request || error.response;
    }
  };

  delete = async (url, config = {}) => {
    try {
      return await axios.delete(this.baseUrl + url, config);
    } catch (error) {
      return error.request || error.response;
    }
  };

  head = async (url, config = {}) => {
    try {
      return await axios.head(this.baseUrl + url, config);
    } catch (error) {
      return error.request || error.response;
    }
  };

  options = async (url, config = {}) => {
    try {
      return await axios.options(this.baseUrl + url, {
        ...config,
        cancelToken: this.source.token,
      });
    } catch (error) {
      return error.request || error.response;
    }
  };

  post = async (url, data = null, config = {}) => {
    try {
      return await axios.post(this.baseUrl + url, data, config);
    } catch (error) {
      return error.request || error.response;
    }
  };

  put = async (url, data = null, config = {}) => {
    try {
      return await axios.put(this.baseUrl + url, data, config);
    } catch (error) {
      return error.request || error.response;
    }
  };

  patch = async (url, data = null, config = {}) => {
    try {
      return await axios.patch(this.baseUrl + url, data, config);
    } catch (error) {
      return error.request || error.response;
    }
  };
}

export default AxiosService;
